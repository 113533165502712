import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Footer.css'
import {SocialButton} from "./SocialButton";
import {Container} from "react-bootstrap";

export function Footer() {
    return (
        <Container>
            <footer id={"footer"} className={"py-3 my-4 border-top"}>
                <small id={"footer-text"} className={"text-muted"}>Agile Tour Brussels, by Re-Evented</small>
                <img id={"footer-logo"} src={"/logo-atbru.png"} alt={"logo"}/>
                <span id={"footer-socialss"}>
                    <SocialButton url={"https://twitter.com/agiletourbruss"} logo={"/twitter.svg"}/>
                    <SocialButton url={"https://www.linkedin.com/company/agiletourbrussels/"} logo={"/linkedin.svg"}/>
                    <SocialButton url={"https://www.instagram.com/agiletourbrussels/"} logo={"/instagram.svg"}/>
                </span>
                <a className={"text-muted"} style={{gridRow:2,justifyContent:'center',alignItems:'center',gridColumn:'1/4',placeSelf:'center',marginTop:"0.5%"}} href={'mailto:info@agiletourbrussels.org'}>info@agiletourbrussels.org</a>
            </footer>
        </Container>)
}