import {createContext, useContext} from "react";

const DomainContext = createContext(undefined);

export function DomainProvider(props) {
    return (
        <DomainContext.Provider value={process.env.REACT_APP_DOMAIN_URL}>
            {props.children}
        </DomainContext.Provider>
    )
}

export const useDomainContext = () => useContext(DomainContext);