import {Link} from "react-router-dom";

export function SessionRowFromList(props){
    const {session, numberOfReviews,user, currentPresenter,index}=props

    // wtf
    return(session&&
        <tr className={"sessionTableRow"}>
            <td>{index!==undefined&&index!==null?index+1:''}</td>{/*'index!==undefined&&index!==null' can not be replaced by 'index?'*/}
            <td ><Link className={"sessionListLink"} to={'/session/'+session.id}> {session.title!==undefined?session.title:"undef"} </Link></td>
            <td>{session.presenters!==undefined?(session.presenters.length<2?<Link to={"/profile/" + session.presenters[0].id} className={"sessionListLink"}>{session.presenters[0].firstName+" "+session.presenters[0].lastName}</Link>:session.presenters[1]!==""?<><Link to={"/profile/" + session.presenters[0].id} className={"sessionListLink"}>{(session.presenters[0].firstName+" "+session.presenters[0].lastName)}</Link><div className={"d-inline"}> & </div><Link to={"/profile/" + session.presenters[1].id} className={"sessionListLink"}>{session.presenters[1].firstName+" "+session.presenters[1].lastName}</Link></>:<Link to={"/profile/" + session.presenters[0].id} className={"sessionListLink"}>{session.presenters[0].firstName+" "+session.presenters[0].lastName}</Link>):"undef"}</td>
            {user && currentPresenter?.role === "admin" && <>
            <td>{numberOfReviews}</td></>}
            {user && currentPresenter?.role === "admin" && <>
                <td>{session.totalVotes?session.totalVotes:0}</td></>}
            <td style={session.topic!==undefined?topicStyle(session.topic):topicStyle("undef")}>{session.topic!==undefined?session.topic:""}</td>
            {user && currentPresenter?.role === "admin" && <>
            <td>{session.created!==undefined?<>{(new Date(session.created.seconds*1000)).getDate()}/{(new Date(session.created.seconds*1000)).getMonth()+1}/{(new Date(session.created.seconds*1000)).getFullYear()} {(new Date(session.created.seconds*1000)).getHours()<10?"0"+(new Date(session.created.seconds*1000)).getHours():(new Date(session.created.seconds*1000)).getHours()}:{(new Date(session.created.seconds*1000)).getMinutes()<10?"0"+(new Date(session.created.seconds*1000)).getMinutes():(new Date(session.created.seconds*1000)).getMinutes()}</>:"undef"}</td>
            <td>{session.modified!==undefined?<>{(new Date(session.modified.seconds*1000)).getDate()}/{(new Date(session.modified.seconds*1000)).getMonth()+1}/{(new Date(session.modified.seconds*1000)).getFullYear()} {(new Date(session.modified.seconds*1000)).getHours()<10?"0"+(new Date(session.modified.seconds*1000)).getHours():(new Date(session.modified.seconds*1000)).getHours()}:{(new Date(session.modified.seconds*1000)).getMinutes()<10?"0"+(new Date(session.modified.seconds*1000)).getMinutes():(new Date(session.modified.seconds*1000)).getMinutes()}</>:""}</td>
            </>}
        </tr>
    )
}

function topicStyle(topic){
    if (topic === "Devops"){
        return {backgroundColor: "#c7FFB1", color: "black"}
    }
    if (topic === "Agile for digital transformation"){
        return {backgroundColor: "#FED28E", color: "black"}
    }
    if (topic === "Agile for sustainability"){
        return {backgroundColor: "#FEC9DC", color: "black"}
    }
    if (topic === "Agile in public sector"){
        return {backgroundColor: "#D6F9FF", color: "black"}
    }
    if (topic === "The future of agile"){
        return {backgroundColor: "#FFFF99", color: "black"}
    }
    return {color: "black"}
}