import {FormCard} from "./FormCard";
import {Alert, Form, Spinner} from "react-bootstrap";
import {FormSubmitButton} from "./FormSubmitButton";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {sendPasswordResetEmail} from "firebase/auth";
import {fbAuth} from "../services/firebase";
import '../styles/UserForm.css';
import {useDomainContext} from "../contexts/domainContext";


function handleSubmitForgotPassword(email, setSendingEmail, setError, setOtherError, domain, navigate) {
    setSendingEmail(true);
    const formError = {};
    if (!email || (email.indexOf('@') === -1)) {
        setSendingEmail(false);
        return;
    }
    const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: domain + 'reset-password',
        handleCodeInApp: true,
    };
    sendPasswordResetEmail(fbAuth, email, actionCodeSettings)
        .then(() => {
            navigate('/forgot-password-confirmation');
        })
        .catch((error) => {
            if (error.message.includes("invalid-email")) {
                formError.email = "Invalid email.";
                setError(formError);
            }
            else if (error.message.includes("user-not-found")) {
                formError.email = "No user with that email found.";
                setError(formError);
            }
            else setOtherError(error.message);
        })

}

export function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [sendingEmail, setSendingEmail] = useState(false);
    const [changedFields, setChangedFields] = useState({})
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState({});
    const [otherError, setOtherError] = useState("");
    const domain = useDomainContext();
    const navigate = useNavigate();
    console.log(error);
    console.log(otherError);

    useEffect(() => {
        if (Object.keys(error).length > 0 || otherError.length > 0) {
            setSendingEmail(false);
        }
    }, [error, otherError])

    return (
        <div className={"d-flex flex-column align-items-sm-center pt-3"} style={{height: "100%"}}>
            <FormCard title="Forgot Password" className={"ms-3 me-3 my-auto shadow user-form"}>
                <Form noValidate validated={validated}
                      onSubmit={(e) => {
                          e.preventDefault();
                          setError({});
                          setOtherError("");
                          setValidated(true);
                          setChangedFields({});
                          handleSubmitForgotPassword(email, setSendingEmail, setError, setOtherError, domain, navigate);
                      }}>
                    <Form.Group className={"mb-3"}>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control className={"inputFieldSessionEdit"} type={"email"} value={email} placeholder={"john@doe.com"}
                                      onChange={(e) => {
                                          setEmail(e.target.value);
                                          setChangedFields({...changedFields, email: true});
                                      }}
                                      isValid={!!!error.email && validated}
                                      isInvalid={!!error.email && !changedFields.email}
                                      required/>
                        <Form.Control.Feedback type={"invalid"}>{error.email ? error.email : email ? "Please provide a valid email address." : 'Please provide your email.'}</Form.Control.Feedback>
                    </Form.Group>
                    <FormSubmitButton variant="primary" type="submit" disabled={sendingEmail}>
                        {
                            sendingEmail &&
                            <Spinner as={"span"} animation={"border"} size={"sm"} className={"me-1"}/>
                        }
                        {sendingEmail ? "Requesting new password..." : "Request new password"}
                    </FormSubmitButton>
                    {
                        otherError.length > 0 &&
                        <Alert className={"mt-3"} variant={"danger"}>{otherError}</Alert>
                    }
                </Form>
            </FormCard>
        </div>
    )
}