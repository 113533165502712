import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {Container} from "react-bootstrap";

export function Layout(props) {
    const {children} = props;
    return (
        <div style={{display: "grid", minHeight: "100vh", gridTemplateRows: "auto 1fr auto"}}>
                <Header/>
                <Container fluid style={{maxWidth: '100vw'}}>
                    {children}
                </Container>
                <Footer/>
        </div>
    )
}