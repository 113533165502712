import {dbApi} from "../services/firebase";
import {useState,useMemo,useEffect} from "react";

export function StatisticsPage(){
    const reviewsPromise = useMemo(() => dbApi.getAllReviews(), []);
    const sessionsPromise = useMemo(() => dbApi.getAllSessions(), []);
    const presentersPromise = useMemo(() => dbApi.getAllPresenters(), []);
    const [allReviews,setAllReviews] = useState([]);
    const [allSessions, setAllSessions] = useState([]);
    const [allPresenters, setAllPresenters] = useState([]);
    const [numberOfReviewedSessions,setNumberOfReviewedSessions] = useState()
    const [numberOfPresentersWhoReviewed,setNumberOfPresentersWhoReviewed] = useState()
    const [numberOfUpdatedAfterReview,setNumberOfUpdatedAfterReview] = useState()

    useEffect(() => {
        let reviewedSessions=[]
        let presentersWhoReviewed=[]
        reviewsPromise.then((promisedReview) => {
            setAllReviews(promisedReview)
            promisedReview.forEach(r=>{
                if(!reviewedSessions.includes(r.sessionDocID)){reviewedSessions.push(r.sessionDocID)}
                if(!presentersWhoReviewed.includes(r.reviewedBy)){presentersWhoReviewed.push(r.reviewedBy)}
            })
            setNumberOfReviewedSessions(reviewedSessions.length)
            setNumberOfPresentersWhoReviewed(presentersWhoReviewed.length)
        });
    }, [reviewsPromise]);

    useEffect(() => {
        sessionsPromise.then((promisedSession) => {
            promisedSession.map(s=>{
                s.reviews=[]
                    allReviews.forEach(r=>{
                        if(s.id===r.sessionDocID){s.reviews.push(r)}
                    })
                return s
            })
            setAllSessions(promisedSession.filter(s=>s.verified))
            let updatedAfterReview=0
            promisedSession.filter(s=>s.verified).forEach(s=>{
                if (s.reviews&&s.reviews.length>0){
                    console.log(s.reviews)
                    let updated=false
                    s.reviews.forEach(r=> {
                        if (r.dateCreated<s.modified) {
                            updated=true
                        }
                    })
                    if(updated) {
                        updatedAfterReview++
                    }
                }
            })
            setNumberOfUpdatedAfterReview(updatedAfterReview)
        });
    }, [sessionsPromise,allReviews]);

    useEffect(() => {
        presentersPromise.then((promisedPresenter) => {
            setAllPresenters(promisedPresenter.filter(p=>p.verified))
        });
    }, [presentersPromise]);

    return(<div style={{margin:"0.5em"}}>
        <h1 style={{marginBottom:0}}>Statistics</h1>
        <h2 style={{marginTop:"0.8em"}}>Reviews</h2>
        <div><b>Sessions reviewed: </b>{numberOfReviewedSessions}
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>
                ({(numberOfReviewedSessions/allSessions.length*100).toFixed(1)}% of all {allSessions.length} verified sessions)</div>
        </div>
        <div><b>Total number of reviews: </b>{allReviews.length}</div>
        <div><b>Done by: </b>{numberOfPresentersWhoReviewed} presenters
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>
                ({(numberOfPresentersWhoReviewed/allPresenters.length*100).toFixed(1)}% of all {allPresenters.length} verified presenters)</div>
        </div>
        <h2 style={{marginTop:"0.8em",}}>Session completeness</h2>
        <div style={{fontSize:"1.3em",marginTop:0,marginBottom:0}}>Verified sessions that have:</div>
        <div>
            <b>Description: </b>{allSessions.filter(s=>s.content).length}<div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>
            ({(allSessions.filter(s=>s.content).length/allSessions.length*100).toFixed(1)}% of all {allSessions.length} verified sessions)</div>
        </div>
        <div>
            <b>Outline or timetable: </b>{allSessions.filter(s=>s.outlineOrTimetable).length}<div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>
            ({(allSessions.filter(s=>s.outlineOrTimetable).length/allSessions.length*100).toFixed(1)}% of all {allSessions.length} verified sessions)</div>
        </div>
        <div>
            <b>Most important fields filled in: </b>{allSessions.filter(s=>s.content&&s.sessionType&&s.duration&&s.sessionGoal&&s.outlineOrTimetable).length}<div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>
            ({(allSessions.filter(s=>s.content&&s.sessionType&&s.duration&&s.sessionGoal&&s.outlineOrTimetable).length/allSessions.length*100).toFixed(1)}% of all {allSessions.length} verified sessions)  (fields: description, session type, duration, session goal, outline or timetable)</div>
        </div>
        <div>
            <b>updated after last review: </b>{numberOfUpdatedAfterReview}
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>({numberOfUpdatedAfterReview/numberOfReviewedSessions?(numberOfUpdatedAfterReview/numberOfReviewedSessions*100).toFixed(1):0}% of all {numberOfReviewedSessions} reviewed sessions)</div>
        </div>
        <h2 style={{marginTop:"0.8em",}}>Presenter completeness</h2>
        <div style={{fontSize:"1.3em",marginTop:0,marginBottom:0}}>Verified presenters that have:</div>
        <div>
            <b>Bio: </b>{allPresenters.filter(p=>p.bio).length}
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>({(allPresenters.filter(p=>p.bio).length/allPresenters.length*100).toFixed(1)}% of all {allPresenters.length} verified presenters)</div>
        </div>
        <div>
            <b>Profile picture: </b>{allPresenters.filter(p=>p.profilePicture).length}
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>({(allPresenters.filter(p=>p.profilePicture).length/allPresenters.length*100).toFixed(1)}% of all {allPresenters.length} verified presenters)</div>
        </div>
        <div>
            <b>Twitter: </b>{allPresenters.filter(p=>p.twitter).length}
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>({(allPresenters.filter(p=>p.twitter).length/allPresenters.length*100).toFixed(1)}% of all {allPresenters.length} verified presenters)</div>
        </div>
        <div>
            <b>Website: </b>{allPresenters.filter(p=>p.website).length}
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>({(allPresenters.filter(p=>p.website).length/allPresenters.length*100).toFixed(1)}% of all {allPresenters.length} verified presenters)</div>
        </div>
        <div>
            <b>Completed presenters: </b>{allPresenters.filter(p=>p.bio&&p.profilePicture&&p.twitter&&p.website).length}
            <div className={"d-inline"} style={{fontSize:"0.87em",marginLeft:"0.8em"}}>({(allPresenters.filter(p=>p.bio&&p.profilePicture&&p.twitter&&p.website).length/allPresenters.length*100).toFixed(1)}% of all {allPresenters.length} verified presenters) (presenters who have a bio, profile picture, twitter and website)</div>
        </div>
    </div>)
}