import {FormCard} from "./FormCard";
import {Alert, Form, Spinner} from "react-bootstrap";
import {FormSubmitButton} from "./FormSubmitButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword} from "firebase/auth";
import {fbAuth} from "../services/firebase";

function handleResetPassword(actionCode, password, setSettingPassword, error, setError, navigate) {
    if (password[0] !== password[1]) {
        setError({...error, password: 'Passwords don\'t match.'})
        return;
    }
    if (!password[0]) return;
    setSettingPassword(true);
    const formError = {};
    verifyPasswordResetCode(fbAuth, actionCode)
        .then((email) => {
            confirmPasswordReset(fbAuth, actionCode, password[0])
                .then(() => {
                    signInWithEmailAndPassword(fbAuth, email, password[0])
                        .then(() => navigate('/reset-password-success'));
                })
                .catch((error) => {
                    if (error.message.includes("weak-password")) {
                        formError.password = "Password is too weak.";
                        setError(formError);
                    }
                });
        });
}

export function ResetPassword() {
    const [searchParams] = useSearchParams();
    const actionCode = searchParams.get('oobCode');
    const [password, setPassword] = useState(['', '']);
    const [validated, setValidated] = useState(false);
    const [changedFields, setChangedFields] = useState({})
    const [settingPassword, setSettingPassword] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (error) setSettingPassword(false);
    }, [error])

    return (
        <div className={"d-flex flex-column align-items-sm-center pt-3"} style={{height: "100%"}}>
            <FormCard title="Reset Password" className={"ms-3 me-3 my-auto"} style={{width: '520px'}}>
                <Form noValidate validated={validated}
                      onSubmit={(e) => {
                          e.preventDefault();
                          setError({});
                          setValidated(true);
                          setChangedFields({});
                          handleResetPassword(actionCode, password, setSettingPassword, error, setError, navigate);
                      }}>
                    <Form.Group className={"mb-3"}>
                        <Form.Label>New password:</Form.Label>
                        <Form.Control className={"inputFieldSessionEdit"} type={"password"} value={password[0]} placeholder={"******"}
                                      onChange={(e) => {
                                          setPassword([e.target.value, password[1]]);
                                          setChangedFields({...changedFields, password: true});
                                      }}
                                      isValid={!!!error.password && validated}
                                      isInvalid={!!error.password && !changedFields.password}
                                      required/>
                        <Form.Control.Feedback type={"invalid"}>{error.password ? error.password : 'Please provide a password.'}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={"mb-3"}>
                        <Form.Label>Confirm new password:</Form.Label>
                        <Form.Control className={"inputFieldSessionEdit"} type={"password"} value={password[1]} placeholder={"******"}
                                      onChange={(e) => setPassword([password[0], e.target.value])}
                                      required/>
                        <Form.Control.Feedback type={"invalid"}>Please confirm your password.</Form.Control.Feedback>
                    </Form.Group>
                    <FormSubmitButton variant="primary" type="submit" disabled={settingPassword}>
                        {
                            settingPassword &&
                            <Spinner as={"span"} animation={"border"} size={"sm"} className={"me-1"}/>
                        }
                        {settingPassword ? "Submitting..." : "Submit"}
                    </FormSubmitButton>
                    {
                        error.length > 0 &&
                        <Alert className={"mt-3"} variant={"danger"}>{error}</Alert>
                    }
                </Form>
            </FormCard>
        </div>
    )
}