export function SuccessIcon() {
    const style = {
        display: 'grid',
        placeItems: 'center',
        alignSelf: 'center',
        width: '1.7em',
        height: '1.7em',
        aspectRatio: '1 / 1',

        fontSize: '1.7em',
        color: 'green',
        border: '2px solid',
        borderRadius: '50%'
    }
    return (
        <div style={style}>✓</div>
    )
}