import '../styles/SessionCardsPrintPage.css';
import {SessionCard} from "../components/SessionCard";

export function SessionsPrintPage(props) {
    let {sessions} = props;
    sessions = sessions.sort((a, b) => {
        if (a.refNumber > b.refNumber) return 1;
        if (a.refNumber < b.refNumber) return -1;
        return 0;
    });
    return (
        <div id='sessions-print-page-container'>
            {
                sessions.map((session) => <SessionCard key={session.id} session={session}/>)
            }
        </div>
    )
}