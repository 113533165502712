import {Container} from "react-bootstrap";
import Switch from "react-switch";
import {dbApi} from "../services/firebase";
import {useEffect, useState, useMemo} from "react";

export function ConfigurationPage(){
    const stateVotingPromise = useMemo(() => dbApi.getVotingActiveState(), []);
    const [stateVoting,setStateVoting]=useState(false)
    const stateSessionSubmissionPromise = useMemo(() => dbApi.getSessionSubmissionState(), []);
    const [stateSessionSubmission,setStateSessionSubmission]=useState(false)
    useEffect(() => {
        stateVotingPromise.then((promisedState) => {setStateVoting(promisedState);console.log("voting:"+promisedState)});
    }, [stateVotingPromise]);
    useEffect(() => {
        stateSessionSubmissionPromise.then((promisedState) => {setStateSessionSubmission(promisedState);console.log("sessions:"+promisedState)});
    }, [stateSessionSubmissionPromise]);
    return(
        <Container className={"container-fluid"}>
            <h1>Configuration</h1>
            <div>
                <h2 style={{fontSize:"1.8em"}} >Voting active:
                <Switch activeBoxShadow={"rgba(2, 117, 148, 0.52) 0 0 4px 4px"} onColor={"#027594"} checked={stateVoting} onChange={()=>dbApi.setVotingActiveState(!stateVoting).then(()=>setStateVoting(!stateVoting))}/>
                </h2>
            </div>
            <div>
                <h2 style={{fontSize:"1.8em"}} >Session submission active:
                    <Switch activeBoxShadow={"rgba(2, 117, 148, 0.52) 0 0 4px 4px"} onColor={"#027594"} checked={stateSessionSubmission} onChange={()=>dbApi.setSessionSubmissionState(!stateSessionSubmission).then(()=>setStateSessionSubmission(!stateSessionSubmission))}/>
                </h2>
            </div>
        </Container>

    )
}


