import Form from 'react-bootstrap/Form';

function Time(props) {
    const {time, times, setTimes, index, isEdit, style} = props;
    return (
        <>
            {
                isEdit &&
                <Form.Control
                    type={'text'}
                    className={'program-time-cell'}
                    onChange={(e) => {
                        let modifiedTimes = times;
                        let modifiedTime = modifiedTimes[index];
                        modifiedTime.time = e.target.value;
                        modifiedTimes[index] = modifiedTime;
                        setTimes(modifiedTimes);
                    }}
                    defaultValue={time}
                    style={style}>
                </Form.Control>
            }
            {
                !isEdit &&
                <div className={'program-time-cell'} style={style}>{time}</div>
            }
        </>
    )
}

export function Times(props) {
    const {times, setTimes, isEdit} = props;
    return (
        <>
            {
                times.map((time, index) =>
                    <Time time={time.time} times={times} setTimes={setTimes} index={index} isEdit={isEdit}
                          key={'times ' + index}
                          style={{
                              gridRow: time.y + 2,
                              gridColumn: 1
                          }}/>
                )
            }
        </>
    )
}