import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Alert, Form, Spinner} from "react-bootstrap";
import {signInWithEmailAndPassword} from "firebase/auth";
import {fbAuth} from "../services/firebase";
import {FormCard} from "./FormCard";
import {FormSubmitButton} from "./FormSubmitButton";
import '../styles/UserForm.css';

function handleLogin(email, password, setSigningIn, setError, setOtherError, navigate) {
    if (!email || !password || (email.indexOf('@') === -1)) return;
    const formError = {};
    setSigningIn(true);
    signInWithEmailAndPassword(fbAuth, email, password)
        .then(() => {
            console.log('in handle:', navigate);
            navigate("/dashboard");
        })
        .catch((error) => {
            if (error.message.includes("invalid-email")) {
                formError.email = "Invalid email.";
                setError(formError);
            }
            else if (error.message.includes("user-not-found")) {
                formError.email = "No user with that email found.";
                setError(formError);
            }
            else if (error.message.includes("wrong-password")) {
                formError.password = "Wrong password."
                setError(formError);
            }
            else setOtherError(error.message);
        });
}

export function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [changedFields, setChangedFields] = useState({})
    const [signingIn, setSigningIn] = useState(false);
    const [error, setError] = useState({});
    const [otherError, setOtherError] = useState("");
    const navigate = useNavigate();
    console.log(error);
    console.log(otherError);


    useEffect(() => {
        if (Object.keys(error).length > 0 || otherError.length > 0) {
            setSigningIn(false);
        }
    }, [error, otherError]);

    return (
        <div className={"d-flex flex-column align-items-sm-center pt-3"} style={{height: "100%"}}>
            <FormCard title="Login" className={"ms-3 me-3 my-auto shadow user-form"}>
                <Form noValidate validated={validated}
                      onSubmit={(e) => {
                          e.preventDefault();
                          setError({});
                          setOtherError("");
                          setValidated(true);
                          setChangedFields({});
                          handleLogin(email, password, setSigningIn, setError, setOtherError, navigate);
                      }}>
                    <Form.Group className={"mb-3"}>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control className={"inputFieldSessionEdit"} type={"email"} value={email} placeholder={"john@doe.com"}
                                      onChange={(e) => {
                                          setEmail(e.target.value);
                                          setChangedFields({...changedFields, email: true});
                                      }}
                                      isValid={!!!error.email && validated}
                                      isInvalid={!!error.email && !changedFields.email}
                                      required/>
                        <Form.Control.Feedback type={"invalid"}>{error.email ? error.email : email ? "Please provide a valid email address." : 'Please provide your email.'}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={"mb-3"}>
                        <Form.Label>Password:</Form.Label>
                        <Form.Control className={"inputFieldSessionEdit"} type={"password"} value={password} placeholder={"******"}
                                      onChange={(e) => {
                                          setPassword(e.target.value);
                                          setChangedFields({...changedFields, password: true});
                                      }}
                                      isValid={!!!error.password && validated}
                                      isInvalid={!!error.password && !changedFields.password}
                                      required/>
                        <Form.Control.Feedback type={"invalid"}>{error.password ? error.password : 'Please provide your password.'}</Form.Control.Feedback>
                    </Form.Group>
                    <FormSubmitButton variant="primary" type="submit" disabled={signingIn}>
                        {
                            signingIn &&
                            <Spinner as={"span"} animation={"border"} size={"sm"} className={"me-1"}/>
                        }
                        {signingIn ? "Signing in..." : "Sign in"}
                    </FormSubmitButton>
                    <Link className={'ms-3'} to={'/forgot-password'}>I forgot my password</Link>
                    {
                        otherError.length > 0 &&
                        <Alert className={"mt-3"} variant={"danger"}>{otherError}</Alert>
                    }
                </Form>
            </FormCard>
        </div>

    )
}