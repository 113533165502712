import {SessionView} from "../components/SessionView";
import {HeaderActivePageContext} from "../contexts/headerActivePageContext";

export default function SessionViewPage() {
    return (
        <HeaderActivePageContext.Provider value={null}>
            <SessionView/>
        </HeaderActivePageContext.Provider>
    );
}
