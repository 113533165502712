export function SessionCard(props) {
    const {session} = props
    return (
        <div className="card">
            <div className="card-header">

                <h5 className="card-title"><span className={"border-end"}>{session.refNumber} </span> {session.title}
                </h5>
            </div>
            <div className="card-body" style={{overflowY: "hidden"}}>
                <div className="card-text">{session.shortDescription?.substring(0,550).split('%;;;%').map((l, index) => <p
                    className={'card-text'}
                    id={l + index}>{l}</p>)}</div>
            </div>
            <div className="card-footer text-center m-0">
                <ul style={{padding: 0}}>
                    <li className="list-group-item">{session.presenters[1]?.firstName ? 'Presenters' : 'Presenter'}:{' '}
                        {
                            session.presenters[0] && session.presenters[0].firstName && session.presenters[0].lastName &&
                            session.presenters[0].firstName + ' ' + session.presenters[0].lastName
                        }
                        {/* Second presenter */}
                        {
                            session.presenters[1] && session.presenters[1].firstName && session.presenters[1].lastName &&
                            <div
                                className={"d-inline"}> & {session.presenters[1].firstName + ' ' + session.presenters[1].lastName}</div>
                        }
                    </li>
                    <li className="list-group-item">Duration: {session.duration}</li>
                    <li className="list-group-item">Room: {session.roomSetup}</li>
                </ul>
            </div>
        </div>
    )
}