import {Card, Col, Container, Image, ProgressBar, Row} from "react-bootstrap";
import {useAuthContext} from "../contexts/authContext";
import {Link} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {dbApi} from "../services/firebase";
import {ReviewList} from "./ReviewList";
import {useNavigate} from "react-router-dom";

export function DashBoard() {
    const {user, currentPresenter} = useAuthContext();

    const [reviews, setReviews] = useState([]);
    const [sessions, setSessions] = useState([]);
    // eslint-disable-next-line
    const reviewsPromise = useMemo(() => dbApi.getReviewsOfPresenter(currentPresenter?.id), [currentPresenter]);
    const sessionsPromise = useMemo(() => dbApi.getSessionsOfPresenter(currentPresenter?.id), [currentPresenter]);

    const navigate=useNavigate()

    useEffect(() => {
        reviewsPromise?.then((promisedReviews) => {
            if (!promisedReviews) return;
            if (promisedReviews.length === 0) return;
            const presenterIds = promisedReviews.map((promisedReview) => {
                return promisedReview.reviewedBy;
            });
            dbApi.getPresentersByIds(presenterIds).then((presenters) => {
                const completeReviews = promisedReviews.map((promisedReview) => {
                    presenters.forEach((presenter) => {
                        if (presenter.id === promisedReview.reviewedBy) promisedReview.reviewedBy = presenter;
                    });
                    return promisedReview;
                });
                setReviews(completeReviews);
            });
        });
    }, [reviewsPromise]);

    useEffect(() => {
        sessionsPromise?.then((promisedSessions) => {
            if (!promisedSessions) return;
            if (promisedSessions.length === 0) return;
            setSessions(promisedSessions);
        });
    }, [sessionsPromise]);

    return (
        <>
            {user && currentPresenter && reviews &&
                <div>
                    <Container className={"container-fluid"}>
                        <Row className={"d-flex"}>
                            <Col xs className={"p-2 mt-5"} style={{minWidth:"50%"}}>
                                {currentPresenter.firstName && currentPresenter.lastName ?
                                    <h1 style={{marginLeft: "-0.1em"}}>Welcome {currentPresenter.firstName + " " + currentPresenter.lastName}!</h1> :
                                    <h1>Welcome!</h1>}
                                <h4>Keep giving reviews!!!</h4>
                                <p>You can give feedback on other people's proposals and improve your proposal based on
                                    the feedback you receive. In this way, we will come to a set of quality session
                                    proposals in a transparent, community driven way.</p>
                            </Col>
                            <Col className="p-2">
                                <Image
                                    src="https://img.freepik.com/free-vector/happy-freelancer-with-computer-home-young-man-sitting-armchair-using-laptop-chatting-online-smiling-vector-illustration-distance-work-online-learning-freelance_74855-8401.jpg?w=900&t=st=1667037491~exp=1667038091~hmac=7c71ea8afc8f3cc8065c5ccc05d105e3c8a7b76f0133016cb210a7882dc19611"
                                    className="img-fluid" style={{minWidth: '280px'}} alt="..."/>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row >
                            {/*profile section*/}
                            <Col style={{display:"flex", justifyContent:"space-evenly",alignItems:"stretch" }}>
                                <Card style={{width: '18rem', marginBottom: "0.5em"}}>
                                    <Card.Body>
                                        {getProfileProgress()===100?
                                            <>
                                                <Card.Title>Complete your profile</Card.Title>
                                                <Card.Text>
                                                    Fill in your bio, add a profile image, website link, or Twitter ID to
                                                    complete
                                                    your profile.
                                                    <button onClick={()=>navigate("/profile/"+currentPresenter.id)} className={"btn btn-info"} style={{width:"100%",marginTop:"0.8em"}}>Go to your profile</button>
                                                </Card.Text>
                                            </>:
                                             <>
                                                <Card.Title>You completed your profile</Card.Title>
                                                <Card.Text>
                                                You filled in your profile information. This will help people to connect with your and get an idea of who you are.
                                                <button onClick={()=>navigate("/profile/"+currentPresenter.id)} className={"btn btn-info"} style={{width:"100%",marginTop:"0.8em"}}>Go to your profile</button>
                                                </Card.Text>
                                            </>
                                        }
                                        <ProgressBar variant={"danger"} now={getProfileProgress(currentPresenter)}/>
                                        {/*<Button variant="primary">Go somewhere</Button>*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/*session section*/}
                            <Col style={{display:"flex", justifyContent:"space-evenly",alignItems:"stretch" }}>
                                <Card style={{width: '18rem', marginBottom: "0.5em"}}>
                                    <Card.Body>
                                        <Card.Title>{sessions.length>1?"Sessions you are involved in":"Session you are involved in"}</Card.Title>
                                        <Card.Text>

                                        </Card.Text>
                                    </Card.Body>
                                    {sessions ? <>{sessions.map((s, index) => (
                                        <SessionListRow session={s} key={s.id+index}/>
                                    ))}
                                    </> : <>no sessions</>}
                                </Card>
                            </Col>
                            {/*review section*/}
                            {reviews &&
                                <Col style={{display:"flex", justifyContent:"space-evenly",alignItems:"stretch" }}>
                                    <Card style={{width: '18rem', marginBottom: "0.5em"}}>
                                        <Card.Body>
                                            <Card.Title>{reviews.length === 1 ? "You wrote 1 review" : "You wrote " + reviews.length + " reviews"}</Card.Title>
                                            <Card.Text>
                                                {reviews.length > 0 ?
                                                    "Thank you for helping our other participants. You are a valuable member of our community." :
                                                    "Help our other participants by writing reviews!"
                                                }
                                            </Card.Text>
                                            <ProgressBar variant={"danger"}
                                                         now={reviews.length < 6 ? reviews.length * 20 : 100}/>
                                        </Card.Body>
                                        {reviews ? <ReviewList key={"reviewList"} reviews={reviews}/> : <></>}
                                    </Card>

                                </Col>}

                        </Row>
                    </Container>
                </div>}
            {!user && <div>
                <Container className={"container-fluid"}>
                    <Row className={"d-flex"}>
                        <Col xs className={"p-2 mt-5"}>
                            <Link to={'/' +
                                'login'}><h4>Please log in</h4></Link>
                            <p>You can give feedback on other people's proposals and improve your proposal based on
                                the feedback you receive. In this way, we will come to a set of quality session
                                proposals in a transparent, community driven way.</p>
                            <br/>
                        </Col>
                        <Col className="p-2">
                            <Image
                                src="https://img.freepik.com/free-vector/happy-freelancer-with-computer-home-young-man-sitting-armchair-using-laptop-chatting-online-smiling-vector-illustration-distance-work-online-learning-freelance_74855-8401.jpg?w=900&t=st=1667037491~exp=1667038091~hmac=7c71ea8afc8f3cc8065c5ccc05d105e3c8a7b76f0133016cb210a7882dc19611"
                                className="img-fluid" style={{minWidth: '464px'}} alt="..."/>
                        </Col>
                    </Row>
                </Container>

            </div>}
        </>)
}

function SessionListRow(props){
    const {session}=props

    return(
        <div style={{marginLeft:"1em",marginRight:"1em",borderTop:"1px solid #0000002d"}}>
            <Link to={'/session/'+session.id}>
                <div style={{marginBottom:"0.2em"}}>{session.title}</div>
            </Link>
            <div style={{color:"#0000008e"}}>{getSessionDate(session)}</div>
        </div>
    )
}

function getProfileProgress(presenter){
    let progress = 0;
    if(presenter){
        if(presenter.profilePicture)progress+=25
        if(presenter.bio)progress+=25
        if(presenter.twitter)progress+=25
        if(presenter.website)progress+=25
    }
    return progress
}

function getSessionDate(session){
    if(!session)return 'review undefined'
    if(session.modified&&session.modified.seconds!==session.created.seconds)return "Modified: "+(dbApi.convertTimestampToDate(session.modified).getDate()<10?'0'+dbApi.convertTimestampToDate(session.modified).getDate():dbApi.convertTimestampToDate(session.modified).getDate())+"/"+(dbApi.convertTimestampToDate(session.modified).getMonth()<9?"0"+(dbApi.convertTimestampToDate(session.modified).getMonth()+1):dbApi.convertTimestampToDate(session.modified).getMonth()+1)+"/"+dbApi.convertTimestampToDate(session.modified).getFullYear()+" "+(dbApi.convertTimestampToDate(session.modified).getHours()<10?"0"+dbApi.convertTimestampToDate(session.modified).getHours():dbApi.convertTimestampToDate(session.modified).getHours())+":"+(dbApi.convertTimestampToDate(session.modified).getMinutes()<10?"0"+dbApi.convertTimestampToDate(session.modified).getMinutes():dbApi.convertTimestampToDate(session.modified).getMinutes())
    if(session.created)return "created: "+(dbApi.convertTimestampToDate(session.created).getDate()<10?'0'+dbApi.convertTimestampToDate(session.created).getDate():dbApi.convertTimestampToDate(session.created).getDate())+"/"+(dbApi.convertTimestampToDate(session.created).getMonth()<9?"0"+(dbApi.convertTimestampToDate(session.created).getMonth()+1):dbApi.convertTimestampToDate(session.created).getMonth()+1)+"/"+dbApi.convertTimestampToDate(session.created).getFullYear()+" "+(dbApi.convertTimestampToDate(session.created).getHours()<10?"0"+dbApi.convertTimestampToDate(session.created).getHours():dbApi.convertTimestampToDate(session.created).getHours())+":"+(dbApi.convertTimestampToDate(session.created).getMinutes()<10?"0"+dbApi.convertTimestampToDate(session.created).getMinutes():dbApi.convertTimestampToDate(session.created).getMinutes())
    return 'date undefined'
}