import {useEffect, useMemo, useState} from "react";
import {dbApi} from "../services/firebase";
import {Link} from "react-router-dom";

export function ReviewList(props){
const {reviews}=props
    //console.log(reviews)
    return(
        <div>
            {reviews?.map((r)=><ReviewListRow key={r.id} review={r}/>)}
        </div>
    )
}

function ReviewListRow(props){
    const {review}=props
    const sessionPromise = useMemo(() => dbApi.getSession(review.sessionDocID), [review.sessionDocID]);
    const [session, setSession] = useState({});
    useEffect(() => {
        sessionPromise.then((promisedSession) => setSession(promisedSession));
    }, [sessionPromise]);
    return(
        <div style={{marginLeft:"1em",marginRight:"1em",borderTop:"1px solid #0000002d"}}>
            <Link to={'/session/'+session.id}>
                <div style={{marginBottom:"0.2em"}}>{session.title}</div>
            </Link>
            <div style={{color:"#0000008e"}}>{getReviewDate(review)}</div>
        </div>
    )
}

function getReviewDate(review){
    if(!review)return 'review undefined'
    if(review.dateModified)return "Modified: "+(dbApi.convertTimestampToDate(review.dateModified).getDate()<10?'0'+dbApi.convertTimestampToDate(review.dateModified).getDate():dbApi.convertTimestampToDate(review.dateModified).getDate())+"/"+(dbApi.convertTimestampToDate(review.dateModified).getMonth()<9?"0"+(dbApi.convertTimestampToDate(review.dateModified).getMonth()+1):dbApi.convertTimestampToDate(review.dateModified).getMonth()+1)+"/"+dbApi.convertTimestampToDate(review.dateModified).getFullYear()+" "+(dbApi.convertTimestampToDate(review.dateModified).getHours()<10?"0"+dbApi.convertTimestampToDate(review.dateModified).getHours():dbApi.convertTimestampToDate(review.dateModified).getHours())+":"+(dbApi.convertTimestampToDate(review.dateModified).getMinutes()<10?"0"+dbApi.convertTimestampToDate(review.dateModified).getMinutes():dbApi.convertTimestampToDate(review.dateModified).getMinutes())
    if(review.dateCreated)return "Created: "+(dbApi.convertTimestampToDate(review.dateCreated).getDate()<10?'0'+dbApi.convertTimestampToDate(review.dateCreated).getDate():dbApi.convertTimestampToDate(review.dateCreated).getDate())+"/"+(dbApi.convertTimestampToDate(review.dateCreated).getMonth()<9?"0"+(dbApi.convertTimestampToDate(review.dateCreated).getMonth()+1):dbApi.convertTimestampToDate(review.dateCreated).getMonth()+1)+"/"+dbApi.convertTimestampToDate(review.dateCreated).getFullYear()+" "+(dbApi.convertTimestampToDate(review.dateCreated).getHours()<10?"0"+dbApi.convertTimestampToDate(review.dateCreated).getHours():dbApi.convertTimestampToDate(review.dateCreated).getHours())+":"+(dbApi.convertTimestampToDate(review.dateCreated).getMinutes()<10?"0"+dbApi.convertTimestampToDate(review.dateCreated).getMinutes():dbApi.convertTimestampToDate(review.dateCreated).getMinutes())
    return 'date undefined'
}

