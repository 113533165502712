import Form from 'react-bootstrap/Form';

function Location(props) {
    const {title, locations, setLocations, index, isEdit, style} = props;
    return (
        <>
            {
                isEdit &&
                <Form.Control
                    type={'text'}
                    className={'program-location-cell'}
                    onChange={(e) => {
                        let modifiedLocations = locations;
                        let modifiedLocation = modifiedLocations[index];
                        modifiedLocation.title = e.target.value;
                        modifiedLocations[index] = modifiedLocation;
                        setLocations(modifiedLocations);
                    }}
                    defaultValue={title}
                    style={style}>
                </Form.Control>
            }
            {
                !isEdit &&
                <div className={'program-location-cell'} style={style}>{title}</div>
            }
        </>
    )
}

export function Locations(props) {
    const {locations, setLocations, isEdit} = props;
    return (
        <>
            {
                locations.map((location, index) =>
                    <Location title={location.title} locations={locations} setLocations={setLocations} index={index}
                              isEdit={isEdit} key={'locations ' + index}
                              style={{
                                  gridRow: 1,
                                  gridColumn: location.x + 2
                              }}/>
                )
            }
        </>
    )
}