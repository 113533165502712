import {useEffect, useMemo, useState} from "react";
import {VotesRowFromSummaryList} from "../components/VotesRowFromSummaryList";
import {Row, Table} from "react-bootstrap";
import {VotesRowFromAllVotesList} from "../components/VotesRowFromAllVotesList";
import {dbApi} from "../services/firebase";

export function VotesPage() {
    const [votes, setVotes] = useState([]);
    const [sessions, setSessions] = useState([]);
    const votesPromise = useMemo(() => dbApi.getVotes(), []);

    useEffect(() => {
        votesPromise.then((promisedVotes) => {
            const sessionIds = promisedVotes.map((vote) => vote.sessionID);
            dbApi.getSessionsByIds(sessionIds).then((promisedSessions) => {
                promisedSessions = promisedSessions.map((promisedSession) => {
                    let totalVotes = 0;
                    promisedVotes.forEach((vote) => {
                        if (vote.sessionID === promisedSession.id) totalVotes++;
                    });
                    promisedSession.totalVotes = totalVotes;
                    return promisedSession;
                });
                setSessions(promisedSessions);

                promisedVotes = promisedVotes.map((promisedVote) => {
                    promisedSessions.forEach((promisedSession) => {
                        if (promisedSession.id === promisedVote.sessionID) {
                            promisedVote.session = promisedSession;
                        }
                    });
                    return promisedVote;
                });
                setVotes(promisedVotes);
            });
        });
    }, [votesPromise]);

    const [sortConditionSummary, setSortConditionSummary] = useState(() => sortByVotesReverse)
    const [sortConditionAllVotes, setSortConditionAllVotes] = useState(() => sortByCreated)

    return (
        <>
            <h1 >Votes</h1>
            <Row>
                <div className={"col-lg-6 col-sm-12"}>
                    <h2>Summary of the votes per Session</h2>
                    <Table bordered className={"sessionTable"}>
                        <thead>
                        <tr>
                            <th onClick={()=>setSortConditionSummary(sortConditionSummary!==sortByTitle?()=>sortByTitle:()=>sortByTitleReverse)}>Session{sortConditionSummary===sortByTitle?<> &#8595;</>:''}{sortConditionSummary===sortByTitleReverse?<> &#8593;</>:''}</th>
                            <th onClick={()=>setSortConditionSummary(sortConditionSummary!==sortByVotes?()=>sortByVotes:()=>sortByVotesReverse)} style={{width:'auto'}}>#Votes{sortConditionSummary===sortByVotes?<> &#8595;</>:''}{sortConditionSummary===sortByVotesReverse?<> &#8593;</>:''}</th>
                        </tr>
                        {sessions.sort(sortConditionSummary).map(s=><VotesRowFromSummaryList key={s.id} session={s}/>)}
                        </thead>
                    </Table>
                </div>
                <div className={"col-lg-6 col-sm-12"}>
                    <h2>All Votes</h2>
                    <Table bordered className={"sessionTable"}>
                        <thead>
                        <tr>
                            <th onClick={()=>setSortConditionAllVotes(sortConditionAllVotes!==sortBySession?()=>sortBySession:()=>sortBySessionReverse)}>Session{sortConditionAllVotes===sortBySession?<> &#8595;</>:''}{sortConditionAllVotes===sortBySessionReverse?<> &#8593;</>:''}</th>
                            <th onClick={()=>setSortConditionAllVotes(sortConditionAllVotes!==sortByVotedBy?()=>sortByVotedBy:()=>sortByVotedByReverse)}>Voted by{sortConditionAllVotes===sortByVotedBy?<> &#8595;</>:''}{sortConditionAllVotes===sortByVotedByReverse?<> &#8593;</>:''}</th>
                            <th onClick={()=>setSortConditionAllVotes(sortConditionAllVotes!==sortByCreated?()=>sortByCreated:()=>sortByCreatedReverse)}>Date of vote{sortConditionAllVotes===sortByCreated?<> &#8595;</>:''}{sortConditionAllVotes===sortByCreatedReverse?<> &#8593;</>:''}</th>
                        </tr>
                        {votes?.sort(sortConditionAllVotes).map(vote => {
                            return (<VotesRowFromAllVotesList key={vote.id} session={getSession(vote,sessions)} vote={vote}/>)
                        })}
                        </thead>
                    </Table>
                </div>
            </Row>
        </>
    )
}

function getSession(vote, sessions) {
    const sessionsFiltered = sessions?.filter(s=>s!==undefined)
    for (let i=0;i<sessionsFiltered.length;i++) {
        if(sessionsFiltered[i].id===vote.sessionID) return sessionsFiltered[i]
    }
    console.error("the session does not exist, vote ID:"+vote.id+" session ID:"+vote.sessionID)
    return null
}

//----------------------------sort functions----------------------------//
function sortByTitle(a,b) {
    if(a.title===undefined||a.title===null){return 1}
    if(b.title===undefined||b.title===null){return -1}
    if ( a.title.toUpperCase() < b.title.toUpperCase()){
        return -1;
    }
    if ( a.title.toUpperCase() > b.title.toUpperCase()){
        return 1;
    }
    return 0;
}

function sortByTitleReverse(a,b) {
    if(a.title===undefined||a.title===null){return -1}
    if(b.title===undefined||b.title===null){return 1}
    if ( a.title.toUpperCase() > b.title.toUpperCase() ){
        return -1;
    }
    if ( a.title.toUpperCase() < b.title.toUpperCase() ){
        return 1;
    }
    return 0;
}

function sortByVotes(a,b) {
    if(a.totalVotes===undefined||a.totalVotes===null){return 1}
    if(b.totalVotes===undefined||b.totalVotes===null){return -1}
    if ( a.totalVotes < b.totalVotes){
        return -1;
    }
    if ( a.totalVotes > b.totalVotes){
        return 1;
    }
    return 0;
}

function sortByVotesReverse(a,b) {
    if(a.totalVotes===undefined||a.totalVotes===null){return -1}
    if(b.totalVotes===undefined||b.totalVotes===null){return 1}
    if ( a.totalVotes > b.totalVotes){
        return -1;
    }
    if ( a.totalVotes < b.totalVotes){
        return 1;
    }
    return 0;
}

function sortByCreated(a,b) {
    if(a.dateCreated===undefined||a.dateCreated===null){return 1}
    if(b.dateCreated===undefined||b.dateCreated===null){return -1}
    if ( a.dateCreated < b.dateCreated ){
        return -1;
    }
    if ( a.dateCreated > b.dateCreated ){
        return 1;
    }
    return 0;
}

function sortByCreatedReverse(a,b) {
    if(a.dateCreated===undefined||a.dateCreated===null){return -1}
    if(b.dateCreated===undefined||b.dateCreated===null){return 1}
    if ( a.dateCreated > b.dateCreated ){
        return -1;
    }
    if ( a.dateCreated < b.dateCreated ){
        return 1;
    }
    return 0;
}

function sortBySession(a,b) {
    if (!a.session) return 1
    if (!b.session) return -1
    if (!a.session.title) return 1
    if (!b.session.title) return -1
    if (a.session.title.toUpperCase() < b.session.title.toUpperCase()) return -1;
    if (a.session.title.toUpperCase() > b.session.title.toUpperCase()) return 1;
    return 0;
}

function sortBySessionReverse(a,b) {
    if(a.session===undefined||a.session===null){return -1}
    if(b.session===undefined||b.session===null){return 1}
    if(a.session.title===undefined||a.session.title===null){return -1}
    if(b.session.title===undefined||b.session.title===null){return 1}
    if ( a.session.title.toUpperCase() > b.session.title.toUpperCase() ){
        return -1;
    }
    if ( a.session.title.toUpperCase() < b.session.title.toUpperCase() ){
        return 1;
    }
    return 0;
}

function sortByVotedBy(a,b) {
    if(a.votedBy===undefined||a.votedBy===null){return 1}
    if(b.votedBy===undefined||b.votedBy===null){return -1}
    if ( a.votedBy.toUpperCase() < b.votedBy.toUpperCase()){
        return -1;
    }
    if ( a.votedBy.toUpperCase() > b.votedBy.toUpperCase()){
        return 1;
    }
    return 0;
}

function sortByVotedByReverse(a,b) {
    if(a.votedBy===undefined||a.votedBy===null){return -1}
    if(b.votedBy===undefined||b.votedBy===null){return 1}
    if ( a.votedBy.toUpperCase() > b.votedBy.toUpperCase() ){
        return -1;
    }
    if ( a.votedBy.toUpperCase() < b.votedBy.toUpperCase() ){
        return 1;
    }
    return 0;
}
