import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {dbApi, fbAuth} from "../services/firebase";

const AuthContext = createContext(undefined);

export function AuthProvider(props) {
    const [userFromFB, userFromFBLoading] = useAuthState(fbAuth);
    const [currentPresenter, setCurrentPresenter] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [reload, setReload] = useState({});
    const reloadAuthState = () => setReload({});

    useEffect(() => {
        if (userFromFBLoading) return;
        if (userFromFB) {
            dbApi.getPresenterByUserId(userFromFB.uid)
                .then((presenter) => {
                    setUser(userFromFB);
                    setCurrentPresenter(presenter);
                });
        }
        else {
            setUser(undefined);
            setCurrentPresenter(undefined);
        }
    }, [userFromFB, userFromFBLoading, reload]);


    const api = useMemo(() => ({
        user, currentPresenter, reloadAuthState
    }), [user, currentPresenter]);

    return <AuthContext.Provider value={api}>
        {props.children}
    </AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext);