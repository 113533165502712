import {Link} from "react-router-dom";
import {useMemo, useState, useEffect} from "react";
import {dbApi} from "../services/firebase";

export function VotesRowFromAllVotesList(props) {
    const {vote} = props
    const [presenters, setPresenter] = useState()
    const [votedBy, setVotedBy] = useState()
    const voterPromise = useMemo(() => dbApi.getPresenter(vote.votedBy), [vote.votedBy]);
    const presentersPromise = useMemo(() => dbApi.getPresentersByIds(getPresentersIds(vote?.session?.presenters)), [vote?.session?.presenters]);
    useEffect(() => {
        presentersPromise.then((promisedPresenters) => setPresenter(promisedPresenters));
    }, [presentersPromise]);
    useEffect(() => {
        voterPromise.then((promisedVoter) => setVotedBy(promisedVoter));
    }, [voterPromise]);
    return (
        <tr className={"sessionTableRow"}>
            {vote.session !== undefined && vote.session !== null && presenters ?
                <td style={vote.session.topic !== undefined ? topicStyle(vote.session.topic) : topicStyle("undef")}>
                    <Link
                        to={'/session/' + vote.session.id}> {vote.session.title !== undefined ? vote.session.title : "undef"} </Link>
                    <div
                        style={{fontSize: "0.9em"}}>by {presenters ? (presenters[0] ? ((presenters[1]&&(presenters[1].firstName!==''&&presenters[1].lastName!==''))? (<><Link
                        to={"/profile/" + presenters[0].id}
                        className={"sessionListLink"}>{presenters[0].firstName + " " + presenters[0].lastName}</Link>
                        <div className={"d-inline"}> & </div>
                        <Link to={"/profile/" + presenters[1].id}
                              className={"sessionListLink"}>{presenters[1].firstName + " " + presenters[1].lastName}</Link></>) : (
                        <Link to={"/profile/" + presenters[0].id}
                              className={"sessionListLink"}>{presenters[0].firstName + " " + presenters[0].lastName}</Link>)) : ("undefined")) : ("undefined")}</div>
                </td> : <td>undef</td>
            }
            <td>{votedBy !== undefined ? <Link to={"/profile/" + votedBy.id}
                                               className={"sessionListLink"}>{votedBy.firstName + " " + votedBy.lastName}</Link> : 'undef'}</td>
            <td>{vote.dateCreated ? <>{(new Date(vote.dateCreated.seconds * 1000)).getDate()}/{(new Date(vote.dateCreated.seconds * 1000)).getMonth() + 1}/{(new Date(vote.dateCreated.seconds * 1000)).getFullYear()} {(new Date(vote.dateCreated.seconds * 1000)).getHours() < 10 ? "0" + (new Date(vote.dateCreated.seconds * 1000)).getHours() : (new Date(vote.dateCreated.seconds * 1000)).getHours()}:{(new Date(vote.dateCreated.seconds * 1000)).getMinutes() < 10 ? "0" + (new Date(vote.dateCreated.seconds * 1000)).getMinutes() : (new Date(vote.dateCreated.seconds * 1000)).getMinutes()}</> : "not defined"}</td>
        </tr>
    )
}

function topicStyle(topic) {
    if (topic === "Devops") {
        return {backgroundColor: "#c7FFB1", color: "black"}
    }
    if (topic === "Agile for digital transformation") {
        return {backgroundColor: "#FED28E", color: "black"}
    }
    if (topic === "Agile for sustainability") {
        return {backgroundColor: "#FEC9DC", color: "black"}
    }
    if (topic === "Agile in public sector") {
        return {backgroundColor: "#D6F9FF", color: "black"}
    }
    if (topic === "The future of agile") {
        return {backgroundColor: "#FFFF99", color: "black"}
    }
    return {color: "black"}
}

function getPresentersIds(presenters) {
    if (presenters)
        return presenters.filter(p => p !== "" && p !== null && p !== undefined)
}