import {useAuthContext} from "../contexts/authContext";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export function UserOnly(props){
    const {user, loading} = useAuthContext();
    const navigate = useNavigate();
    if(user){return <>{props.children}</>}
    else if (!loading) {
        return(
            <>
                <h1 style={{textAlign:"center",marginTop:"5rem",fontSize:"4rem",color:"#027594"}}>Please log in to access this page!</h1>
                <div style={{textAlign:"center"}}><Button onClick={()=>navigate('/login')} style={{textAlign:"center",marginTop:"15%",fontSize:"3rem",borderRadius:"0.5em"}} className={"btn btn-info"}>login</Button></div>
            </>)
    }
}
