import {SessionRowFromList} from "../components/SessionRowFromList";
import {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {dbApi} from "../services/firebase";
import {useAuthContext} from "../contexts/authContext";
import Dropdown from 'react-bootstrap/Dropdown';
import {Table} from "react-bootstrap";
import {SessionsPrintPage} from "./SessionsPrintPage";
import ReactDOM from 'react-dom'

const SortBy = {
    Title: 'title',
    Presenters: 'presenters',
    Reviews: 'reviews',
    Votes: 'votes',
    Topic: 'topic',
    Created: 'created',
    Modified: 'modified',
}

const Arrangement = {
    Ascending: true, Descending: false,
}

export default function SessionsPage() {
    const [sessions, setSessions] = useState(null);
    const [cancelledSessions, setCancelledSessions] = useState(null);
    const [sortedSessions, setSortedSessions] = useState(null);
    const [sortedCancelledSessions, setSortedCancelledSessions] = useState(null);
    const [loadedSortFromStorage, setLoadedSortFromStorage] = useState(false);
    const [reviews, setReviews] = useState(null);
    const [votes, setVotes] = useState(null);
    const [sessionsToPrint, setSessionsToPrint] = useState([]);
    const [topicToPrint, setTopicToPrint] = useState('');
    const sessionsPromise = useMemo(() => dbApi.getAllSessions(), []);
    const presentersPromise = useMemo(() => dbApi.getAllPresenters(), []);
    const reviewsPromise = useMemo(() => dbApi.getAllReviews(), []);
    const votesPromise = useMemo(() => dbApi.getVotes(), []);
    const {user, currentPresenter} = useAuthContext();

    // Initialize sessions and insert associated presenters into them
    useEffect(() => {
        Promise.all([sessionsPromise, presentersPromise])
            .then(([promisedSessions, promisedPresenters]) => {
                const promisedSessionsWithResolvedPresenters = promisedSessions.map((promisedSession) => {
                    promisedSession.presenters = promisedSession.presenters.map((presenterOfSession) => {
                        const resolvedPresenter = promisedPresenters.filter((promisedPresenter) => {
                            return presenterOfSession === promisedPresenter.id;
                        })[0];
                        if (!resolvedPresenter) return '';
                        return resolvedPresenter;
                    });
                    return promisedSession;
                });
                setSessions(promisedSessionsWithResolvedPresenters);
            });
    }, [presentersPromise, sessionsPromise]);

    // Load reviews
    useEffect(() => {
        reviewsPromise.then((promisedReviews) => {
            setReviews(promisedReviews);
        });
    }, [reviewsPromise]);

    // Load votes
    useEffect(() => {
        votesPromise.then((promisedVotes) => {
            setVotes(promisedVotes);
        });
    }, [votesPromise]);

    // Add total reviews and votes to sessions
    useEffect(() => {
        if (!sessions || !sessions.length > 0 || !reviews || !votes) return;
        if (sortedSessions || sortedCancelledSessions) return;

        // Add reviews to sessions
        const sessionsWithReviews = sessions.map((session) => {
            session.totalReviews = reviews.filter(review => review.sessionDocID === session.id).length;
            return session;
        });

        // Add votes to sessions
        let sessionsWithReviewsAndVotes = sessionsWithReviews.map((session) => {
            session.totalVotes = votes.filter((vote) => vote.sessionID === session.id).length;
            return session;
        });

        setSessions(sessionsWithReviewsAndVotes);

        // Also set cancelled sessions
        const cancelledSessions = sessionsWithReviewsAndVotes.filter((session) => session.state === "Cancelled");
        setCancelledSessions(cancelledSessions);

        // Filter out cancelled sessions
        sessionsWithReviewsAndVotes = sessionsWithReviewsAndVotes.filter((session) => session.state !== "Cancelled");

        // Initial sorting
        sort(sessionsWithReviewsAndVotes, SortBy.Created, Arrangement.Descending, setSortedSessions, setSortCondition);
        sort(cancelledSessions, SortBy.Created, Arrangement.Descending, setSortedCancelledSessions, setSortConditionForCancelled);
    }, [sessions, reviews, votes]);

    useLayoutEffect(() => {
        if (!sortedSessions) return;
        const printIF = document.getElementById('sessions-print-iframe').contentWindow.document;
        let styles = '';
        for (let stylesheet of document.styleSheets) {
            if (stylesheet.cssRules) {
                for (let cssRule of stylesheet.cssRules) styles += cssRule.cssText;
            }
        }
        styles = "<style>" + styles + "</style>"
        printIF.open();
        printIF.write(styles + "<div id='iframe-content'/>");
        printIF.close();
    }, [sortedSessions]);

    useEffect(() => {
        if (!topicToPrint) return;
        let filteredSessions = sortedSessions.slice();
        if (topicToPrint !== 'All') filteredSessions = sortedSessions.filter((session) => session.topic === topicToPrint && session.state !== 'Cancelled');
        setSessionsToPrint(filteredSessions);
        setTopicToPrint('');
    }, [sortedSessions, topicToPrint]);

    useEffect(() => {
        if (sessionsToPrint.length === 0) return;
        const cardContainer = document.getElementById('sessions-print-iframe').contentWindow;
        cardContainer.focus();
        cardContainer.print();
        setSessionsToPrint([]);
    }, [sessionsToPrint]);

    const [currentSortCondition, setSortCondition] = useState(null);
    const [currentSortConditionForCancelled, setSortConditionForCancelled] = useState(null);

    // Save sorts to session storage on change
    useEffect(() => {
        if (!loadedSortFromStorage) return;
        sessionStorage.setItem('sort_sessions', JSON.stringify(currentSortCondition));
        sessionStorage.setItem('sort_cancelled_sessions', JSON.stringify(currentSortConditionForCancelled));
    }, [currentSortCondition, currentSortConditionForCancelled]);

    // Load sorts from session storage
    useEffect(() => {
        if (!sessions || !sortedSessions || !sortedCancelledSessions || loadedSortFromStorage) return;
        const savedSortForSessions = sessionStorage.getItem('sort_sessions');
        const savedSortForCancelledSessions = sessionStorage.getItem('sort_cancelled_sessions');
        if (savedSortForSessions) {
            const parsedSortFromStorage = JSON.parse(savedSortForSessions);
            setSortCondition(parsedSortFromStorage);
            sort(sessions, parsedSortFromStorage.sortBy, parsedSortFromStorage.arrangement, setSortedSessions, setSortCondition);
        }
        if (savedSortForCancelledSessions) {
            const parsedSortFromStorage = JSON.parse(savedSortForCancelledSessions);
            setSortConditionForCancelled(parsedSortFromStorage);
            sort(cancelledSessions, parsedSortFromStorage.sortBy, parsedSortFromStorage.arrangement, setSortedCancelledSessions, setSortConditionForCancelled);
        }
        setLoadedSortFromStorage(true);
    }, [sortedSessions, sortedCancelledSessions]);

    return (<>
        <h1 className={"sessionListTitle"}>Sessions list</h1>
        <div className={"sessionListSubTitle"}>List
            contains <b>{sessions && sessions.filter(s => s.state !== "Cancelled").length}</b> sessions
        </div>
        {sortedSessions && <>
            <div className={"table-responsive-sm"}>
                {sortedSessions && sortedSessions.length > 0 && <Table bordered className={"sessionTable"}>
                    <thead>
                    <tr>
                        <th style={{cursor: "default", width: "2%"}}/>

                        <th onClick={() => sort(sessions, SortBy.Title, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>
                            Title {sortIcon(SortBy.Title, currentSortCondition)}
                        </th>

                        <th onClick={() => sort(sessions, SortBy.Presenters, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>
                            Presenter(s) {sortIcon(SortBy.Presenters, currentSortCondition)}
                        </th>

                        {/* Only admins can see review & vote count */}
                        {user && currentPresenter?.role === "admin" && <>
                            <th
                                onClick={() => sort(sessions, SortBy.Reviews, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>
                                #Reviews {sortIcon(SortBy.Reviews, currentSortCondition)}
                            </th>

                            <th
                                onClick={() => sort(sessions, SortBy.Votes, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>
                                #Votes {sortIcon(SortBy.Votes, currentSortCondition)}
                            </th>
                        </>}

                        <th onClick={() => sort(sessions, SortBy.Topic, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>
                            Topic {sortIcon(SortBy.Topic, currentSortCondition)}
                        </th>

                        {/* Only admins can see created & modified date/times */}
                        {user && currentPresenter?.role === "admin" && <>
                            <th
                                onClick={() => sort(sessions, SortBy.Created, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>
                                Created {sortIcon(SortBy.Created, currentSortCondition)}
                            </th>

                            <th
                                onClick={() => sort(sessions, SortBy.Modified, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>
                                Modified {sortIcon(SortBy.Modified, currentSortCondition)}
                            </th>
                        </>}
                    </tr>
                    {sortedSessions.map((session, index) => <SessionRowFromList session={session}
                                                                                index={index}
                                                                                numberOfReviews={reviews.filter(review => review.sessionDocID === session.id).length}
                                                                                key={session.id} user={user}
                                                                                currentPresenter={currentPresenter}/>)}
                    </thead>
                </Table>}
            </div>

            {currentPresenter?.role === 'admin' && <Dropdown onSelect={(e) => setTopicToPrint(e)}>
                <Dropdown.Toggle variant={'danger'}>
                    Print sessions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item eventKey={'Devops'}>Devops</Dropdown.Item>
                    <Dropdown.Item eventKey={'Agile for digital transformation'}>Agile for digital transformation</Dropdown.Item>
                    <Dropdown.Item eventKey={'The future of agile'}>The future of agile</Dropdown.Item>
                    <Dropdown.Item eventKey={'Agile in public sector'}>Agile in public sector</Dropdown.Item>
                    <Dropdown.Item eventKey={'Agile for sustainability'}>Agile for sustainability</Dropdown.Item>
                    <Dropdown.Item eventKey={'Other'}>Other</Dropdown.Item>
                    <Dropdown.Item eventKey={'All'}>All</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>}
            <iframe title={'sessions-print-iframe'} id={'sessions-print-iframe'} style={{display: 'none'}}>
                {document.getElementById('sessions-print-iframe') && ReactDOM.createPortal(<SessionsPrintPage
                    sessions={sessionsToPrint}/>, document.getElementById('sessions-print-iframe').contentWindow.document.getElementById('iframe-content'))}
            </iframe>
            <h1 style={{marginTop: "1em"}} className={"sessionListTitle"}>Cancelled sessions</h1>
            <div className={"sessionListSubTitle"}>List
                contains <b>{sessions.filter(session => session.state === "Cancelled").length}</b> sessions
            </div>
            <div className={"table-responsive-sm"}>
                <Table bordered className={"sessionTable"}>
                    <thead>
                    <tr>
                        <th style={{cursor: "default", width: "2%"}}/>

                        <th onClick={() => sort(cancelledSessions, SortBy.Title, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>
                            Title {sortIcon(SortBy.Title, currentSortConditionForCancelled)}
                        </th>

                        <th onClick={() => sort(cancelledSessions, SortBy.Presenters, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>
                            Presenter(s) {sortIcon(SortBy.Presenters, currentSortConditionForCancelled)}
                        </th>

                        {/* Only admins can see review & vote count */}
                        {user && currentPresenter?.role === "admin" && <>
                            <th onClick={() => sort(cancelledSessions, SortBy.Reviews, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>
                                #Reviews {sortIcon(SortBy.Reviews, currentSortConditionForCancelled)}
                            </th>

                            <th onClick={() => sort(cancelledSessions, SortBy.Votes, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>
                                #Votes {sortIcon(SortBy.Votes, currentSortConditionForCancelled)}
                            </th>
                        </>}

                        <th onClick={() => sort(cancelledSessions, SortBy.Topic, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>
                            Topic {sortIcon(SortBy.Topic, currentSortConditionForCancelled)}
                        </th>

                        {/* Only admins can see created & modified date/times */}
                        {user && currentPresenter?.role === "admin" && <>
                            <th onClick={() => sort(cancelledSessions, SortBy.Created, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>
                                Created {sortIcon(SortBy.Created, currentSortConditionForCancelled)}
                            </th>

                            <th onClick={() => sort(cancelledSessions, SortBy.Modified, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>
                                Modified {sortIcon(SortBy.Modified, currentSortConditionForCancelled)}
                            </th>
                        </>}
                    </tr>
                    {sortedCancelledSessions.map((session, index) => <SessionRowFromList session={session} index={index}
                                                                                         numberOfReviews={reviews.filter(review => review.sessionDocID === session.id).length}
                                                                                         key={session.id} user={user}
                                                                                         currentPresenter={currentPresenter}/>)}
                    </thead>
                </Table>
            </div>
        </>}
    </>);
}

function sortIcon(sortBy, sortCondition) {
    return sortCondition.sortBy === sortBy ? (sortCondition.arrangement === Arrangement.Ascending ? <>&#8595;</> : <>&#8593;</>) : ""
}

function sort(sessions, by, arrangement, setSortedSessions, setSortCondition) {
    let filteredSessions = sessions.sort((a, b) => {
        let comparison;
        switch (by) {
            case SortBy.Title:
                if (a.title == null) {
                    comparison = 1;
                    break;
                }
                if (b.title == null) {
                    comparison = -1;
                    break;
                }
                comparison = a.title.localeCompare(b.title);
                break;
            case SortBy.Presenters:
                if (a.presenters[0] == null || a.presenters[0].firstName == null) {
                    comparison = 1;
                    break;
                }
                if (b.presenters[0] == null || b.presenters[0].firstName == null) {
                    comparison = -1;
                    break;
                }
                comparison = a.presenters[0].firstName.localeCompare(b.presenters[0].firstName);
                break;
            case SortBy.Reviews:
                if (a.totalReviews == null) {
                    comparison = 1;
                    break;
                }
                if (b.totalReviews == null) {
                    comparison = -1;
                    break;
                }
                comparison = a.totalReviews - b.totalReviews;
                break;
            case SortBy.Votes:
                if (a.totalVotes == null) {
                    comparison = 1;
                    break;
                }
                if (b.totalVotes == null) {
                    comparison = -1;
                    break;
                }
                comparison = a.totalVotes - b.totalVotes;
                break;
            case SortBy.Topic:
                if (!'topic' in a || !a.topic) {
                    comparison = 1;
                    break;
                }
                if (!'topic' in b || !b.topic) {
                    comparison = -1;
                    break;
                }
                comparison = a.topic.localeCompare(b.topic);
                break;
            case SortBy.Created:
                comparison = a.created.seconds - b.created.seconds;
                break;
            case SortBy.Modified:
                comparison = a.modified.seconds - b.modified.seconds;
                break;
        }
        return arrangement === Arrangement.Ascending ? comparison : -comparison;
    });
    setSortedSessions(filteredSessions);
    setSortCondition({
        sortBy: by, arrangement: arrangement,
    });
}

