import {FormCard} from "./FormCard";
import {SuccessIcon} from "./SuccessIcon";
import '../styles/UserForm.css';

export function SuccessDialog(props) {
    const {title, text} = props;
    return (
        <div className={"d-flex flex-column align-items-sm-center pt-3"} style={{height: "100%"}}>
            <FormCard title={title} className={"ms-3 me-3 my-auto shadow user-form"}>
                <div style={{display: 'flex', gap: '1em'}}>
                    <SuccessIcon/>
                    {text}
                </div>

            </FormCard>
        </div>
    )
}