import {initializeApp} from "firebase/app";
import {
    addDoc,
    collection,
    connectFirestoreEmulator,
    deleteDoc,
    deleteField,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    getDocsFromServer,
    getFirestore,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';
import {
    connectAuthEmulator,
    getAuth,
    EmailAuthProvider,
    reauthenticateWithCredential,
    updateEmail
} from 'firebase/auth';
import {connectStorageEmulator, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";


const firebaseConfig = {
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_URL
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const fbFirestore = getFirestore(app);
export const fbAuth = getAuth(app);
export const fbStorage = getStorage(app);

if (process.env.REACT_APP_FIREBASE_EMULATOR_HOST) {
    connectFirestoreEmulator(fbFirestore, process.env.REACT_APP_FIREBASE_EMULATOR_HOST, process.env.REACT_APP_FIREBASE_EMULATOR_PORT);
}
if (process.env.REACT_APP_FIREBASE_AUTH_URL) connectAuthEmulator(fbAuth, process.env.REACT_APP_FIREBASE_AUTH_URL);

if (process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT) connectStorageEmulator(fbStorage, process.env.REACT_APP_FIREBASE_EMULATOR_HOST, process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT)

export const dbApi = {
    getAllSessions: () => getAllSessions(),
    getAllReviews: () => getTable('reviews'),
    getCommentsOnReview: (reviewId) => getCommentsOnReview(reviewId),
    submitComment: (submitterId, reviewId, commentContent) => submitComment(submitterId, reviewId, commentContent),
    editComment: (commentId, content) => editComment(commentId, content),
    createSession: (title, subtitle, shortDescription, presenters) => createSession(title, subtitle, shortDescription, presenters),
    getReviewsOfSession: (sessionId) => getReviewsOfSession(sessionId),
    postReview: (submitterId, sessionId, whatILike, improvementOpportunities, sessionScore) => postReview(submitterId, sessionId, whatILike, improvementOpportunities, sessionScore),
    editReview: (reviewId, whatILike, improvementOpportunities, sessionScore) => editReview(reviewId, whatILike, improvementOpportunities, sessionScore),
    getSession: (sessionId) => getSession(sessionId),
    editSession: (sessionId, session) => editSession(sessionId, session),
    getVotes: () => getTable('votes'),
    getSessionsByIds: (ids) => getSessionsByIds(ids),
    getPresenter: (presenterId) => getPresenter(presenterId),
    getPresenterByUserId: (id) => getPresenterByUserId(id),
    getAllPresenters: () => getAllPresenters(),
    getPresentersByIds: (ids) => getPresentersByIds(ids),
    submitVote: (presenterId, sessionID) => submitVote(presenterId, sessionID),
    getVotesFromPresenter: (presenterId) => getVotesFromPresenter(presenterId),
    hasPresenterVotedOnSession: (presenterId, sessionId) => hasPresenterVotedOnSession(presenterId, sessionId),
    withdrawVote: (presenterId, sessionId) => withdrawVote(presenterId, sessionId),
    getVotingActiveState: () => getVotingActiveState(),
    getSessionSubmissionState: () => getSessionSubmissionState(),
    setVotingActiveState: (state) => setVotingActiveState(state),
    setSessionSubmissionState: (state) => setSessionSubmissionState(state),
    makeAdmin: (role, user) => makeAdmin(role, user),
    getReviewsOfPresenter: (id) => getReviewsOfPresenter(id),
    getSessionsOfPresenter: (id) => getSessionsOfPresenter(id),
    convertTimestampToDate: (timestamp) => convertTimestampToDate(timestamp),
    verifyPresenter: (unverifiedPresenterId, unverifiedPresenterKey, userId) => verifyPresenter(unverifiedPresenterId, unverifiedPresenterKey, userId),
    registerPresenter: (userId, firstName, lastName) => registerPresenter(userId, firstName, lastName),
    uploadProfilePicture: (file, presenterId) => uploadProfilePicture(file, presenterId),
    setProfilePictureLink: (presenterId, url) => setProfilePictureLink(presenterId, url),
    updatePresenter: (presenter, presenterId) => updatePresenter(presenter, presenterId),
    getProgram: (programId) => getProgram(programId),
    getLocationsOfProgram: (programId) => getTable('programs/' + programId + '/locations'),
    getTimesOfProgram: (programId) => getTable('programs/' + programId + '/times'),
    getCellsOfProgram: (programId) => getCellsOfProgram('programs/' + programId + '/cells'),
    updateMailAsUser: (userId, mailEdit, passwordConfirmation, user) => updateMailAsUser(userId, mailEdit, passwordConfirmation, user),
    updateMailAsAdmin: (mailEdit) => updateMailAsAdmin(mailEdit),
    createProgram: (title, locations, times, matrix) => createProgram(title, locations, times, matrix),
    saveProgram: (program, locations, times, matrix) => saveProgram(program, locations, times, matrix),
    getAllPrograms: () => getTable('programs'),
    setPresenterIdToUserId: (presenterId,userId,sessionId,email) => setPresenterIdToUserId(presenterId,userId,sessionId,email),
    setUserData:(userId,email)=>setUserData(userId,email),
    getUserData:(userId)=>getUserData(userId),
    setPresenterHasSubmittedASession: (presenterId) => setPresenterHasSubmittedASession(presenterId),
    getPresenterProfilePicture: (presenterId) => getPresenterProfilePicture(presenterId),
}


async function getTable(table) {
    const q = query(collection(fbFirestore, table));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

async function getAllSessions() {
    const q = query(collection(fbFirestore, 'sessions'), where('verified', '==', true));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

async function getCommentsOnReview(reviewId) {
    const q = query(collection(fbFirestore, 'comments'), where('reviewDocID', '==', reviewId));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

async function submitComment(submitterId, reviewId, commentContent) {
    return addDoc(collection(fbFirestore, 'comments'), {
        commentBy: submitterId,
        commentContent: commentContent,
        reviewDocID: reviewId,
        dateCreated: serverTimestamp()
    }).then((reference) => {
        return reference.id;
    });
}

async function editComment(commentId, content) {
    return updateDoc(doc(collection(fbFirestore, 'comments'), commentId), {
        commentContent: content,
        dateModified: serverTimestamp()
    });
}

async function createSession(title, subtitle, shortDescription, presenters) {
    const snapshot = await getCountFromServer(query(collection(fbFirestore, "sessions"), where("verified", "==", true)));
    const data = {
        title: title,
        subtitle: subtitle,
        shortDescription: shortDescription,
        created: serverTimestamp(),
        modified: serverTimestamp(),
        verified: true,
        state: 'Draft',
        presenters: presenters,
        refNumber: snapshot.data().count + 1
    }
    return addDoc(collection(fbFirestore, "sessions"), data).then((reference) => {
        return reference.id;
    });
}

async function getSession(sessionId) {
    return getDoc(doc(fbFirestore, 'sessions', sessionId)).then((doc) => {
        const session = doc.data();
        session.id = doc.id;
        return session;
    });
}

async function editSession(sessionId, session) {
    delete session.id;
    return updateDoc(doc(fbFirestore, 'sessions/' + sessionId), {
        ...session,
        modified: serverTimestamp()
    });
}

async function getReviewsOfSession(sessionId) {
    const q = query(collection(fbFirestore, 'reviews'), where('sessionDocID', '==', sessionId));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

async function postReview(submitterId, sessionId, whatILike, improvementOpportunities, sessionScore) {
    return addDoc(collection(fbFirestore, 'reviews'), {
        reviewedBy: submitterId,
        sessionDocID: sessionId,
        whatILike: whatILike,
        improvementOpportunities: improvementOpportunities ? improvementOpportunities : '',
        sessionScore: parseInt(sessionScore),
        dateCreated: serverTimestamp(),
    }).then((reference) => {
        return reference.id;
    });
}

async function editReview(reviewId, whatILike, improvementOpportunities, sessionScore) {
    return updateDoc(doc(collection(fbFirestore, 'reviews'), reviewId), {
        whatILike: whatILike,
        improvementOpportunities: improvementOpportunities,
        sessionScore: parseInt(sessionScore),
        dateModified: serverTimestamp()
    });
}

async function getSessionsByIds(ids) {
    if (!ids.length) return [];
    let promises = [];
    while (ids.length) {
        const batch = ids.splice(0, 10);
        const q = query(collection(fbFirestore, 'sessions'), where('__name__', 'in', batch));
        promises.push(getDocs(q).then((docs) => {
            return docs.docs.map((doc) => {
                const data = doc.data();
                data.id = doc.id;
                return data;
            });
        }));
    }

    let result = [];
    const promisedResults = await Promise.all(promises);
    promisedResults.forEach((batch) => {
        result.push(...batch);
    });
    return result;
}

async function getPresenter(presenterId) {
    return await getDoc(doc(fbFirestore, 'presenters', presenterId)).then((doc) => {
        const data = doc.data();
        data.id = presenterId;
        return data;
    });
}

async function getPresenterByUserId(id) {
    const q = query(collection(fbFirestore, 'presenters'), where('user', '==', id));
    return getDocs(q).then((docs) => {
        if (docs.empty) return undefined;
        const data = docs.docs[0].data();
        data.id = docs.docs[0].id;
        return data;
    });
}

// function sortIdsByIds(desiredSort, arrayToSort) {
//     const sortedArray = [];
//     desiredSort.forEach((item, index) => {
//         for (let i = 0; i < arrayToSort.length; i++) {
//             if (desiredSort[index] === arrayToSort[i].id) {
//                 sortedArray.push(arrayToSort[i]);
//                 arrayToSort.splice(i);
//                 break;
//             }
//         }
//     });
//     return sortedArray;
// }

async function getPresentersByIds(ids) {
    if (!ids.length) return [];
    const q = query(collection(fbFirestore, 'presenters'), where('__name__', 'in', ids));
    return getDocs(q).then((docs) => {
        const modifiedDocs = docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
        //return sortIdsByIds(ids, modifiedDocs);
        if (ids.length > 1) {
            return modifiedDocs.filter((p) => p.firstName && p !== '' && p.firstName !== '').sort((a, b) => {
                if (a && !b) {
                    return -1
                }
                if (!a && b) {
                    return 1
                }
                if (a.id === ids[0]) {
                    return -1
                } else return 1
            })
        } else {
            return modifiedDocs.filter((p) => p.firstName && p !== '' && p.firstName !== '')
        }
    });
}

async function submitVote(submitterId, sessionID) {
    return addDoc(collection(fbFirestore, 'votes'), {
        votedBy: submitterId,
        sessionID: sessionID,
        dateCreated: serverTimestamp()
    }).then((reference) => {
        return reference.id;
    });
}

async function getVotesFromPresenter(presenterId) {
    const q = query(collection(fbFirestore, 'votes'), where('votedBy', '==', presenterId));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

async function hasPresenterVotedOnSession(presenterId, sessionId) {
    if (!presenterId || !sessionId) return false;
    const q = query(collection(fbFirestore, 'votes'), where('votedBy', '==', presenterId), where('sessionID', '==', sessionId));
    return getDocs(q).then((docs) => {
        return docs.empty ? false : docs.docs[0].id;
    });
}

async function withdrawVote(presenterId, sessionId) {
    const q = query(collection(fbFirestore, 'votes'), where('votedBy', '==', presenterId), where('sessionID', '==', sessionId));
    return getDocs(q).then((docs) => {
        if (!docs.empty) {
            docs.docs.forEach((vote) => {
                deleteDoc(doc(fbFirestore, 'votes', vote.id))
            })
        }
    });
}

async function getVotingActiveState() {
    try {
        const votingActiveDoc = await getDoc(doc(fbFirestore, "config", "votingActive"))
        const data = votingActiveDoc.data()
        return data.state;
    } catch (error) {
        await setDoc(doc(fbFirestore, "config", "votingActive"), {state: false});
        return false
    }

}

async function getSessionSubmissionState() {
    try {
        const sessionSubmissionDoc = await getDoc(doc(fbFirestore, "config", "sessionSubmission"))
        const data = sessionSubmissionDoc.data()
        return data.state;
    } catch (error) {
        await setDoc(doc(fbFirestore, "config", "sessionSubmission"), {state: false});
        return false
    }

}

async function makeAdmin(role, user) {
    return updateDoc(doc(collection(fbFirestore, 'presenters'), user), {
        role: role
    });
}


async function setVotingActiveState(state) {
    await setDoc(doc(fbFirestore, "config", "votingActive"), {state: state});
}

async function setSessionSubmissionState(state) {
    await setDoc(doc(fbFirestore, "config", "sessionSubmission"), {state: state});
}

async function getReviewsOfPresenter(id) {
    if (!id) return null
    const q = query(collection(fbFirestore, 'reviews'), where('reviewedBy', '==', id));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

async function getSessionsOfPresenter(id) {
    if (!id) return;
    const q = query(collection(fbFirestore, 'sessions'), where("presenters", "array-contains", id));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

function convertTimestampToDate(timestamp) {
    return timestamp.toDate();
}

async function verifyPresenter(unverifiedPresenterId, unverifiedPresenterKey, userId) {
    return updateDoc(doc(collection(fbFirestore, 'presenters'), unverifiedPresenterId), {
        user: userId,
        bio: '',
        profilePicture: '',
        twitter: '',
        website: '',
        keyNeededToVerify: deleteField(),
        keyUsedToVerify: unverifiedPresenterKey,
        verified: true
    });
}

async function registerPresenter(userId, firstName, lastName) {
    return addDoc(collection(fbFirestore, 'presenters'), {
        user: userId,
        firstName,
        lastName,
        bio: '',
        profilePicture: '',
        twitter: '',
        website: '',
        verified: true,
    });
}

async function getAllPresenters() {
    const q = query(collection(fbFirestore, 'presenters'), where('verified', '==', true));
    return getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
    });
}

async function uploadProfilePicture(file, presenterId) {
    const storageRef = ref(fbStorage, `/profilePictures/${presenterId}/${presenterId}`);
    if (!file) {
        console.error("no picture found")
    } else {
        await uploadBytes(storageRef, file);
        return storageRef;
    }
}

async function setProfilePictureLink(presenterId, url) {
    await updateDoc(doc(collection(fbFirestore, 'presenters'), presenterId), {profilePicture: url});
}

async function updatePresenter(presenter, presenterId) {
    if (presenter && presenterId) {
        await updateDoc(doc(collection(fbFirestore, 'presenters'), presenterId), presenter);
    }
}

async function getProgram(programId) {
    return getDoc(doc(fbFirestore, 'programs', programId)).then((doc) => {
        const program = doc.data();
        if (!program) return;
        program.id = doc.id;
        return program;
    });
}

async function getCellsOfProgram(programSessionsPath) {
    const q = query(collection(fbFirestore, programSessionsPath));
    return (await getDocs(q)).docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
    });
}

async function updateMailAsUser(userId, mailEdit, passwordConfirmation, user) {
    return reauthenticateWithCredential(user, EmailAuthProvider.credential(
        user.email,
        passwordConfirmation
    )).then(async (creds) => {
        await updateEmail(creds.user, mailEdit)
        await setUserData(user.uid, user.email)
    });
}

async function updateMailAsAdmin(mailEdit) {
    console.warn("update mail as admin not yet implemented, newMail:", mailEdit)
}

async function createProgram(title, locations, times, matrix) {
    const programRef = await addDoc(collection(fbFirestore, 'programs'), {
        title: title,
        created: serverTimestamp(),
        modified: serverTimestamp()
    });
    const locationsPromises = locations.map((location) =>
        addDoc(collection(fbFirestore, programRef.path + '/locations'), {
            title: location.title,
            x: location.x
        })
    );
    const timesPromises = times.map((time) =>
        addDoc(collection(fbFirestore, programRef.path + '/times'), {
            time: time.time,
            y: time.y
        })
    );
    const matrixPromises = matrix.map((column, columnIndex) =>
        column.map((row, rowIndex) => {
            if (!row) return;
            let data = {
                coordinates: {
                    x: columnIndex,
                    y: rowIndex
                }
            }
            if (row.coordinates.span) {
                data = {
                    coordinates: {
                        x: columnIndex,
                        y: rowIndex,
                        span: true
                    }
                }
            }

            if (row.type === 'session') data.sessionRef = doc(fbFirestore, '/sessions/' + row.object.id);
            if (row.type === 'text') data.text = row.object;
            return addDoc(collection(fbFirestore, programRef.path + '/cells'), data);
        })
    );
    await Promise.all(locationsPromises);
    await Promise.all(timesPromises);
    await Promise.all(matrixPromises);
    return programRef.id
}

async function saveProgram(program, locations, times, matrix) {
    await updateDoc(doc(fbFirestore, 'programs/' + program.id), {
        title: program.title,
        modified: serverTimestamp()
    });

    // Delete all cells of program
    const oldTimesPromise = getDocsFromServer(query(collection(fbFirestore, '/programs/' + program.id + '/times')))
        .then((oldCellsSnapshot) => oldCellsSnapshot.docs.map((cell) => deleteDoc(cell.ref)));
    const oldLocationsPromise = getDocsFromServer(query(collection(fbFirestore, '/programs/' + program.id + '/locations')))
        .then((oldCellsSnapshot) => oldCellsSnapshot.docs.map((cell) => deleteDoc(cell.ref)));
    const oldCellsPromise = getDocsFromServer(query(collection(fbFirestore, '/programs/' + program.id + '/cells')))
        .then((oldCellsSnapshot) => oldCellsSnapshot.docs.map((cell) => deleteDoc(cell.ref)));
    await Promise.all([oldTimesPromise, oldLocationsPromise, oldCellsPromise]);

    const locationsPromises = locations.map((location) =>
            addDoc(collection(fbFirestore, '/programs/' + program.id + '/locations'), {
                title: location.title,
                x: location.x
            })
    );
    const timesPromises = times.map((time) =>
            addDoc(collection(fbFirestore, '/programs/' + program.id + '/times'), {
                time: time.time,
                y: time.y
            })
    );
    const matrixPromises = matrix.map((column, columnIndex) =>
        column.map((row, rowIndex) => {
            if (!row) return;
            let data = {
                coordinates: {
                    x: columnIndex,
                    y: rowIndex
                }
            }
            if (row.coordinates.span) {
                data = {
                    coordinates: {
                        x: columnIndex,
                        y: rowIndex,
                        span: true
                    }
                }
            }

            if (row.type === 'session') data.sessionRef = doc(fbFirestore, '/sessions/' + row.object.id);
            if (row.type === 'text') data.text = row.object;
            return addDoc(collection(fbFirestore, '/programs/' + program.id + '/cells'), data);
        })
    )
    await Promise.all(locationsPromises);
    await Promise.all(timesPromises);
    await Promise.all(matrixPromises);
}

async function setPresenterIdToUserId(presenterId,userId,sessionId,email){
    await getPresenter(presenterId).then(async (presenter) => {
        if(presenter.id){delete presenter.id}
        await setDoc(doc(fbFirestore, "presenters", userId), presenter).then(async () => {
            await deleteDoc(doc(fbFirestore, "presenters", presenterId))
        });
    }).then(async () => {
        await getSession(sessionId).then(async (session) => {
            if(session.presenters[0]===presenterId){
            await updateDoc(doc(fbFirestore, "sessions", sessionId),
                {
                    presenters:[userId,session.presenters[1]]
                }
            )}
            if(session.presenters[1]===presenterId){
                await updateDoc(doc(fbFirestore, "sessions", sessionId),
                    {
                        presenters:[session.presenters[0],userId]
                    }
                )}
            await setUserData(userId, email)
        })
    })
}

async function setUserData(userId,email){
    await setDoc(doc(fbFirestore, "userData", userId), {email:email})
}

async function getUserData(userId){
    return (await getDoc(doc(fbFirestore, "userData", userId))).data()
}

async function setPresenterHasSubmittedASession(presenterId) {
    await updateDoc(doc(fbFirestore, 'presenters', presenterId), {
        submittedASession: true
    });
}

async function getPresenterProfilePicture(presenterId) {
    try {
        return await getDownloadURL(ref(fbStorage, `profilePictures/${presenterId}/${presenterId}`));
    }
    catch (e) {
        return undefined;
    }
}